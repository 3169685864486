// @ is an alias to /src

import Loading from "@/components/Loading";
import SettingsPanel from "@/components/SettingsPanel";
import { DynamicStorage } from "@/helpers/storage";
export default {
  name: "Settings",
  components: {
    Loading,
    SettingsPanel
  },
  data() {
    return {
      counter: 0,
      availableSettings: null,
      familyPrayerSettings: null,
      dailyOfficeSettings: null,
      loading: true,
      windowWidth: 0,
      drawerOpen: true,
      advanced: false,
      openTab: "office",
      bottomPanelExpanded: true
    };
  },
  computed: {
    // a computed getter
    tabPosition: function () {
      return "top";
      // return this.windowWidth > 780 ? "left" : "top";
    }
  },
  async mounted() {
    await this.initialize();
  },
  unmounted() {
    window.removeEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    async initialize() {
      this.loading = true;
      this.windowWidth = window.innerWidth;
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
      this.availableSettings = await this.$store.state.availableSettings;
      await this.$store.dispatch('initializeSettings');
      const settings = await this.$store.state.settings;
      this.availableSettings.forEach((setting, i) => {
        const name = setting.name;
        this.availableSettings[i].active = settings[name];
      });
      this.dailyOfficeSettings = this.availableSettings.filter(setting => setting.site_name == "Daily Office");
      this.familyPrayerSettings = this.availableSettings.filter(setting => setting.site_name == "Family Prayer");
      if (await DynamicStorage.getItem("advancedSettings")) {
        const stored = await DynamicStorage.getItem("advancedSettings");
        this.advanced = stored == "true" ? true : false;
      } else {
        await DynamicStorage.setItem("advancedSettings", false);
      }
      if (await DynamicStorage.getItem("settingsPane")) {
        const stored = await DynamicStorage.getItem("settingsPane");
        if (stored) {
          this.openTab = stored;
        } else {
          await DynamicStorage.setItem("settingsPane", "office");
        }
      }
      this.loading = false;
    },
    async toggleAdvanced(value) {
      await DynamicStorage.setItem("advancedSettings", value);
    },
    async toggleOffice(value) {
      this.openTab = value;
      await DynamicStorage.setItem("settingsPane", value);
    },
    toggleBottomPanel() {
      this.bottomPanelExpanded = !this.bottomPanelExpanded;
    }
  }
};