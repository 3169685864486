import { Share } from "@capacitor/share";
import { Clipboard } from "@capacitor/clipboard";
import { ElMessage } from "element-plus";
import { DynamicStorage } from "@/helpers/storage";
import { getMessageOffset } from "@/helpers/getMessageOffest";
import { Capacitor } from "@capacitor/core";
export default {
  data() {
    return {
      canShare: false,
      sharePanel: false,
      showSharePanel: false,
      panelSize: "37%",
      shareLink: false
    };
  },
  created: async function () {
    window.addEventListener("resize", this.setPanelSize);
    this.setPanelSize();
    const canShare = await Share.canShare();
    this.canShare = canShare.value;
    this.shareLink = await this.getShareLink();
  },
  unmounted() {
    window.removeEventListener("resize", this.setPanelSize);
  },
  methods: {
    setPanelSize() {
      if (window.innerWidth < 1024) {
        this.panelSize = "90%";
      } else {
        this.panelSize = "37%";
      }
    },
    async getCollectProps() {
      const extraCollects = JSON.parse(await DynamicStorage.getItem('extraCollects'));
      if (extraCollects) {
        const queryString = Object.keys(extraCollects).map(key => key.toLowerCase().replace(" ", "_") + "_collects=" + extraCollects[key]).join("&");
        return `&${queryString}`;
      }
      return "";
    },
    async getShareLink() {
      await this.getCollectProps();
      this.availableSettings = await this.$store.state.availableSettings;
      await this.$store.dispatch('initializeSettings');
      const settings = await this.$store.state.settings;
      const queryString = Object.keys(settings).map(key => key + "=" + settings[key]).join("&") + (await this.getCollectProps());
      const path = this.$route.path;
      const port = parseInt(window.location.port);
      const port_string = port == 8080 ? ":8080" : "";
      if (Capacitor.getPlatform() != 'web') {
        return `https://www.dailyoffice2019.com${path}?${queryString}`;
      }
      let url = "";
      if (port_string) {
        url = `${window.location.protocol}//${window.location.hostname}${port_string}${path}?${queryString}`;
      } else {
        url = `${window.location.protocol}//${window.location.hostname}${path}?${queryString}`;
      }
      return url;
    },
    async copyLink() {
      await Clipboard.write({
        string: await this.getShareLink()
      });
      ElMessage.success({
        title: "Saved",
        message: "The Share Link has been copied to the clipboard.</small>",
        showClose: true,
        dangerouslyUseHTMLString: true,
        offset: getMessageOffset()
      });
    },
    async toggleSharePanel() {
      this.showSharePanel = !this.showSharePanel;
      this.shareLink = await this.getShareLink();
    },
    async share(event) {
      event.preventDefault();
      await Share.share({
        title: "Pray the Daily Office",
        text: "Join me in praying the Daily Office using my customized settings",
        url: await this.getShareLink(),
        dialogTitle: "Pray with others"
      });
    }
  }
};