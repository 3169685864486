import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group");
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  const _component_el_menu = _resolveComponent("el-menu");
  const _component_Submenu = _resolveComponent("Submenu");
  const _component_el_header = _resolveComponent("el-header");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_menu, {
    "default-active": $data.activeIndex,
    mode: "horizontal",
    "menu-trigger": "click",
    router: $data.menuRouter,
    "unique-opened": $data.uniqueOpened,
    class: "fixed top-0 left-0 right-0 account-for-notch"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_sub_menu, {
      index: "1"
    }, {
      title: _withCtx(() => [_createTextVNode("Pray")]),
      default: _withCtx(() => [_createVNode(_component_el_menu_item, {
        index: "/"
      }, {
        default: _withCtx(() => [_createTextVNode(" Current Day and Time")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/readings"
      }, {
        default: _withCtx(() => [_createTextVNode(" Today's Readings")]),
        _: 1
      }), _createVNode(_component_el_menu_item_group, {
        title: "— Daily Office —"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu_item, {
          index: "/office/evening_prayer/yesterday"
        }, {
          default: _withCtx(() => [_createTextVNode(" Evening Prayer (Yesterday) ")]),
          _: 1
        }), _createVNode(_component_el_menu_item, {
          index: "/office/compline/yesterday"
        }, {
          default: _withCtx(() => [_createTextVNode(" Compline (Yesterday) ")]),
          _: 1
        }), _createVNode(_component_el_menu_item, {
          index: "/office/morning_prayer"
        }, {
          default: _withCtx(() => [_createTextVNode(" Morning Prayer ")]),
          _: 1
        }), _createVNode(_component_el_menu_item, {
          index: "/office/midday_prayer"
        }, {
          default: _withCtx(() => [_createTextVNode(" Midday Prayer ")]),
          _: 1
        }), _createVNode(_component_el_menu_item, {
          index: "/office/evening_prayer"
        }, {
          default: _withCtx(() => [_createTextVNode(" Evening Prayer ")]),
          _: 1
        }), _createVNode(_component_el_menu_item, {
          index: "/office/compline"
        }, {
          default: _withCtx(() => [_createTextVNode(" Compline")]),
          _: 1
        }), _createVNode(_component_el_menu_item, {
          index: "/office/morning_prayer/tomorrow"
        }, {
          default: _withCtx(() => [_createTextVNode(" Morning Prayer (Tomorrow) ")]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_menu_item_group, {
        title: "— Family Prayer —"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu_item, {
          index: "/family/early_evening_prayer/yesterday"
        }, {
          default: _withCtx(() => [_createTextVNode(" Early Evening (Yesterday) ")]),
          _: 1
        }), _createVNode(_component_el_menu_item, {
          index: "/family/close_of_day_prayer/yesterday"
        }, {
          default: _withCtx(() => [_createTextVNode(" Close of Day (Yesterday) ")]),
          _: 1
        }), _createVNode(_component_el_menu_item, {
          index: "/family/morning_prayer"
        }, {
          default: _withCtx(() => [_createTextVNode(" Morning")]),
          _: 1
        }), _createVNode(_component_el_menu_item, {
          index: "/family/midday_prayer"
        }, {
          default: _withCtx(() => [_createTextVNode(" Midday")]),
          _: 1
        }), _createVNode(_component_el_menu_item, {
          index: "/family/early_evening_prayer"
        }, {
          default: _withCtx(() => [_createTextVNode(" Early Evening ")]),
          _: 1
        }), _createVNode(_component_el_menu_item, {
          index: "/family/close_of_day_prayer"
        }, {
          default: _withCtx(() => [_createTextVNode(" Close of Day ")]),
          _: 1
        }), _createVNode(_component_el_menu_item, {
          index: "/family/morning_prayer/tomorrow"
        }, {
          default: _withCtx(() => [_createTextVNode(" Morning (Tomorrow) ")]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_menu_item, {
      index: "/settings"
    }, {
      default: _withCtx(() => [_createTextVNode(" Settings")]),
      _: 1
    }), _createVNode(_component_el_sub_menu, {
      index: "3"
    }, {
      title: _withCtx(() => [_createTextVNode(" Resources")]),
      default: _withCtx(() => [_createVNode(_component_el_menu_item, {
        index: "/calendar"
      }, {
        default: _withCtx(() => [_createTextVNode(" Calendar ")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/about"
      }, {
        default: _withCtx(() => [_createTextVNode(" About")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/collects"
      }, {
        default: _withCtx(() => [_createTextVNode(" Collects")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/psalms"
      }, {
        default: _withCtx(() => [_createTextVNode(" Psalms")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/litany"
      }, {
        default: _withCtx(() => [_createTextVNode(" Great Litany")]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["default-active", "router", "unique-opened"]), _createVNode(_component_el_header, null, {
    default: _withCtx(() => [_createVNode(_component_Submenu)]),
    _: 1
  })], 64);
}