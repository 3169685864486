import Loading from "@/components/Loading";
export default {
  components: {
    Loading
  },
  data() {
    return {
      passage: null,
      data: null,
      error: null,
      loading: true,
      esv: "-",
      kjv: "-",
      rsv: "-"
    };
  },
  async created() {
    let data = null;
    this.passage = this.$route.params.passage;
    try {
      const data = await this.$http.get(`${process.env.VUE_APP_API_URL}api/v1/scripture/${this.passage}`);
      this.data = data.data;
      this.kjv = data.data.kjv;
      this.rsv = data.data.rsv;
      this.esv = data.data.esv;
    } catch (e) {
      this.error = "There was an error retrieving the passage. Please try again.";
      this.loading = false;
      return;
    }
    this.error = false;
    this.loading = false;
  }
};