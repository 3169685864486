// @ is an alias to /src

import { DynamicStorage } from "@/helpers/storage";
export default {
  name: "BetaNote",
  components: {},
  data() {
    return {
      displayBetaNote: true
    };
  },
  async created() {
    if ((await DynamicStorage.getItem('betaNoteDismissed')) === 'true') {
      this.displayBetaNote = false;
    } else {
      this.displayBetaNote = true;
    }
  },
  methods: {
    dismissNote: async function (reading) {
      await DynamicStorage.setItem('betaNoteDismissed', 'true');
    }
  }
};