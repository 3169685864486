import "core-js/modules/es.array.push.js";
// @ is an alias to /src

import { DynamicStorage } from "@/helpers/storage";
export default {
  name: "Calendar",
  components: {},
  data() {
    return {
      year: null,
      month: null,
      days: {},
      date: null,
      loading: true,
      includeMinorFeasts: false
    };
  },
  watch: {
    "$route.params.year": function () {
      this.setCalendar();
    },
    "$route.params.month": function () {
      this.setCalendar();
    }
  },
  async created() {
    this.setCalendar();
  },
  methods: {
    updateIncludeMinorFeasts: async function () {
      const includeMinorFeasts = this.includeMinorFeasts ? "true" : "false";
      await DynamicStorage.setItem("includeMinorFeasts", includeMinorFeasts);
    },
    getColorForDate: function (day) {
      try {
        let commemorations = this.days[day].commemorations;
        if (this.includeMinorFeasts) {
          commemorations = commemorations.filter(commemorations => {
            return commemorations.rank.name.includes("FERIA") == false;
          });
          if (!commemorations.length) {
            return this.days[day].season.colors[0];
          }
          return commemorations[0]['colors'][0];
        } else {
          if (this.days[day].major_feast) {
            return commemorations[0]['colors'][0];
          }
          return this.days[day].season.colors[0];
        }
      } catch {
        return "";
      }
    },
    getFeastNameForDate: function (day) {
      let feast = "";
      let bold = false;
      try {
        feast = this.days[day].major_feast;
        if (feast) {
          bold = true;
        }
      } catch {
        feast = "";
      }
      if (this.includeMinorFeasts && !feast) {
        try {
          feast = this.days[day].major_or_minor_feast;
        } catch {
          feast = "";
        }
      }
      if (bold) {
        return `<strong>${feast}</strong>`;
      }
      return feast;
    },
    selectDate: async function (changeType) {
      if (changeType == "prev-month") {
        if (this.month == 1) {
          this.year = this.year - 1;
          this.month = 12;
        } else {
          this.month = this.month - 1;
        }
      }
      if (changeType == "next-month") {
        if (this.month == 12) {
          this.year = this.year + 1;
          this.month = 1;
        } else {
          this.month = this.month + 1;
        }
      }
      if (changeType == "today") {
        const today = new Date();
        this.year = today.getFullYear();
        this.month = today.getMonth() + 1;
      }
      const year = this.year;
      const month = this.month;
      await this.$router.push({
        name: "calendar",
        params: {
          year,
          month
        }
      });
      return;
    },
    setCalendar: async function () {
      this.loading = true;
      const includeMinorFeasts = (await DynamicStorage.getItem("includeMinorFeasts")) || "false";
      this.includeMinorFeasts = includeMinorFeasts == "true";
      this.updateIncludeMinorFeasts();
      let data = null;
      const today = new Date();
      let year = parseInt(this.$route.params.year);
      let month = parseInt(this.$route.params.month);
      if (!year) {
        year = today.getFullYear();
        month = today.getMonth() + 1;
      } else if (!month) {
        month = 1;
      }
      this.year = year;
      this.month = month;
      this.date = new Date(this.year, this.month - 1, 1);
      try {
        data = await this.$http.get(`${process.env.VUE_APP_API_URL}api/v1/calendar/${this.year}-${this.month}`);
      } catch (e) {
        this.error = "There was an error retrieving the office. Please try again.";
        this.loading = false;
        return;
      }
      data.data.forEach(day => {
        const dateString = day["date"];
        this.days[dateString] = day;
      });
      this.loading = false;
    },
    clickDateCell: async function (data, event) {
      event.preventDefault();
      event.stopPropagation();
      const day = data.day.split("-");
      await this.$router.push({
        name: `day`,
        params: {
          year: day[0],
          month: day[1],
          day: day[2]
        }
      });
      return;
    }
  }
};