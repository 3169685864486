import "core-js/modules/es.array.push.js";
// @ is an alias to /src

import { DynamicStorage } from "@/helpers/storage";
export default {
  name: "OfficeNav",
  components: {},
  props: {
    calendarDate: {
      type: Date
    },
    selectedOffice: {
      type: String
    },
    serviceType: {
      default: "office",
      type: String
    }
  },
  data() {
    return {
      links: null,
      dayLink: null,
      currentServiceType: this.serviceType
    };
  },
  async created() {
    const tomorrow = new Date(this.calendarDate);
    tomorrow.setDate(this.calendarDate.getDate() + 1);
    const yesterday = new Date(this.calendarDate);
    yesterday.setDate(this.calendarDate.getDate() - 1);
    this.dailyLinks = [{
      to: `/morning_prayer/${this.calendarDate.getFullYear()}/${this.calendarDate.getMonth() + 1}/${this.calendarDate.getDate()}`,
      text: "Morning<br>Prayer",
      name: "morning_prayer",
      icon: ["fad", "sunrise"]
    }, {
      to: `/midday_prayer/${this.calendarDate.getFullYear()}/${this.calendarDate.getMonth() + 1}/${this.calendarDate.getDate()}`,
      text: "Midday<br>Prayer",
      name: "midday_prayer",
      icon: ["fad", "sun"]
    }, {
      to: `/evening_prayer/${this.calendarDate.getFullYear()}/${this.calendarDate.getMonth() + 1}/${this.calendarDate.getDate()}`,
      text: "Evening<br>Prayer",
      name: "evening_prayer",
      icon: ["fad", "sunset"]
    }, {
      to: `/compline/${this.calendarDate.getFullYear()}/${this.calendarDate.getMonth() + 1}/${this.calendarDate.getDate()}`,
      text: "Compline<br>(Bedtime)",
      name: "compline",
      icon: ["fad", "moon-stars"]
    }];
    this.readingsLink = {
      to: `/readings/${this.calendarDate.getFullYear()}/${this.calendarDate.getMonth() + 1}/${this.calendarDate.getDate()}`,
      text: "Day's Readings",
      name: "readings",
      icon: ["fad", "book-bible"]
    }, this.familyLinks = [{
      to: `/family/morning_prayer/${this.calendarDate.getFullYear()}/${this.calendarDate.getMonth() + 1}/${this.calendarDate.getDate()}`,
      text: "Morning",
      name: "morning_prayer",
      icon: ["fad", "sunrise"]
    }, {
      to: `/family/midday_prayer/${this.calendarDate.getFullYear()}/${this.calendarDate.getMonth() + 1}/${this.calendarDate.getDate()}`,
      text: "Midday",
      name: "midday_prayer",
      icon: ["fad", "sun"]
    }, {
      to: `/family/early_evening_prayer/${this.calendarDate.getFullYear()}/${this.calendarDate.getMonth() + 1}/${this.calendarDate.getDate()}`,
      text: "Early Evening",
      name: "early_evening_prayer",
      icon: ["fad", "sunset"]
    }, {
      to: `/family/close_of_day_prayer/${this.calendarDate.getFullYear()}/${this.calendarDate.getMonth() + 1}/${this.calendarDate.getDate()}`,
      text: "Close of Day",
      name: "close_of_day_prayer",
      icon: ["fad", "moon-stars"]
    }];
    if (this.currentServiceType == "family") {
      this.links = this.familyLinks;
    } else {
      this.links = this.dailyLinks;
    }
    const servicePart = this.currentServiceType == "family" ? `/${this.currentServiceType}` : '';
    this.dayLinks = [{
      to: `${servicePart}/${this.selectedOffice}/${yesterday.getFullYear()}/${yesterday.getMonth() + 1}/${yesterday.getDate()}`,
      icon: "left",
      text: yesterday.toLocaleDateString("en-us", {
        weekday: "long"
      })
    }, {
      to: `${servicePart}/${this.selectedOffice}/${this.calendarDate.getFullYear()}/${this.calendarDate.getMonth() + 1}/${this.calendarDate.getDate()}`,
      text: this.calendarDate.toLocaleDateString("en-us", {
        weekday: "long"
      }),
      selected: true
    }, {
      to: `${servicePart}/${this.selectedOffice}/${tomorrow.getFullYear()}/${tomorrow.getMonth() + 1}/${tomorrow.getDate()}`,
      icon: "right",
      text: tomorrow.toLocaleDateString("en-us", {
        weekday: "long"
      })
    }];
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    hoverClass(name) {
      return name == this.selectedOffice ? "always" : "hover";
    },
    selectedClass(name) {
      return name == this.selectedOffice ? "selected" : "";
    },
    redirectToDaily() {
      if (this.selectedOffice) {
        const lookup = {
          morning_prayer: "morning_prayer",
          midday_prayer: "midday_prayer",
          early_evening_prayer: "evening_prayer",
          close_of_day_prayer: "compline"
        };
        const new_office = lookup[this.selectedOffice];
        if (new_office) {
          this.$router.push(`/office/${new_office}/${this.calendarDate.getFullYear()}/${this.calendarDate.getMonth() + 1}/${this.calendarDate.getDate()}`);
        }
      }
    },
    redirectToFamily() {
      if (this.selectedOffice) {
        const lookup = {
          morning_prayer: "morning_prayer",
          midday_prayer: "midday_prayer",
          evening_prayer: "early_evening_prayer",
          compline: "close_of_day_prayer"
        };
        const new_office = lookup[this.selectedOffice];
        if (new_office) {
          this.$router.push(`/family/${new_office}/${this.calendarDate.getFullYear()}/${this.calendarDate.getMonth() + 1}/${this.calendarDate.getDate()}`);
        }
      }
    },
    async toggleServiceType() {
      if (this.currentServiceType == "family") {
        this.currentServiceType = "office";
        this.links = this.dailyLinks;
        await DynamicStorage.setItem("serviceType", "office");
        this.redirectToDaily();
      } else {
        this.currentServiceType = "family";
        await DynamicStorage.setItem("serviceType", "family");
        this.links = this.familyLinks;
        this.redirectToFamily();
      }
    }
  }
};