import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock(_Fragment, null, [!$props.line.extra_space_before ? (_openBlock(), _createElementBlock("p", {
    key: 0,
    class: _normalizeClass($options.indentClass)
  }, [$props.line.preface ? (_openBlock(), _createElementBlock("sup", _hoisted_1, _toDisplayString($props.line.preface) + " ", 1)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString($props.line.content.replace(" *", "*").replace("*", " *")), 1)], 2)) : _createCommentVNode("", true), $props.line.extra_space_before ? (_openBlock(), _createElementBlock("p", {
    key: 1,
    class: _normalizeClass(["extra-space-before", $options.indentClass])
  }, [$props.line.preface ? (_openBlock(), _createElementBlock("sup", _hoisted_2, _toDisplayString($props.line.preface), 1)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString($props.line.content.replace(" *", "*").replace("*", " *")), 1)], 2)) : _createCommentVNode("", true)], 64);
}