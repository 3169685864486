export default {
  data() {
    return {
      emailField: null,
      emailPanel: false,
      windowWidth: window.innerWidth,
      success: false,
      error: null,
      loading: false,
      panelSize: "37%"
    };
  },
  created: function () {
    window.addEventListener("resize", this.setPanelSize);
    this.setPanelSize();
  },
  unmounted() {
    window.removeEventListener("resize", this.setPanelSize);
  },
  methods: {
    setPanelSize() {
      if (window.innerWidth < 1024) {
        this.panelSize = "90%";
      } else {
        this.panelSize = "37%";
      }
    },
    showEmailPanel() {
      this.emailPanel = true;
    },
    onSubmit() {
      this.loading = true;
      this.success = false;
      this.error = null;
      const url = `${process.env.VUE_APP_API_URL}api/v1/email_signup`;
      const params = {
        email: this.emailField
      };
      this.$http.post(url, params).then(response => {
        this.success = `Thanks for signing up! We'll send an occasional email to ${this.emailField} when a new feature is launched.`;
        this.emailField = null;
        this.loading = false;
      }).catch(error => {
        try {
          this.error = error.response.data[0];
          this.loading = false;
        } catch {
          this.error = "There was an unknown error. Please contact feedback@dailyoffice2019.com";
        }
        this.loading = false;
      });
    }
  }
};