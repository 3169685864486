import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Office = _resolveComponent("Office");
  const _component_PageNotFound = _resolveComponent("PageNotFound");
  return _openBlock(), _createElementBlock(_Fragment, null, [!$data.notFound ? (_openBlock(), _createBlock(_component_Office, {
    key: 0,
    "service-type": $data.serviceType,
    office: $data.office,
    "calendar-date": $data.calendarDate
  }, null, 8, ["service-type", "office", "calendar-date"])) : _createCommentVNode("", true), $data.notFound ? (_openBlock(), _createBlock(_component_PageNotFound, {
    key: 1
  })) : _createCommentVNode("", true)], 64);
}