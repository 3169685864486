// @ is an alias to /src

export default {
  name: "Office Leader",
  components: {},
  props: ["line"],
  computed: {
    indentClass() {
      if (!this.line.indented) {
        return "";
      }
      return this.line.indented;
    }
  }
};