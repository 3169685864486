import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-779e06e4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home office"
};
const _hoisted_2 = {
  class: "small-container"
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  id: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PageNotFound = _resolveComponent("PageNotFound");
  const _component_Loading = _resolveComponent("Loading");
  const _component_CalendarCard = _resolveComponent("CalendarCard");
  const _component_el_alert = _resolveComponent("el-alert");
  const _component_OfficeNav = _resolveComponent("OfficeNav");
  const _component_FontSizer = _resolveComponent("FontSizer");
  const _component_OfficeHeading = _resolveComponent("OfficeHeading");
  const _component_OfficeSubheading = _resolveComponent("OfficeSubheading");
  const _component_OfficeCitation = _resolveComponent("OfficeCitation");
  const _component_OfficeHTML = _resolveComponent("OfficeHTML");
  const _component_OfficeLeader = _resolveComponent("OfficeLeader");
  const _component_OfficeLeaderDialogue = _resolveComponent("OfficeLeaderDialogue");
  const _component_OfficeCongregation = _resolveComponent("OfficeCongregation");
  const _component_OfficeCongregationDialogue = _resolveComponent("OfficeCongregationDialogue");
  const _component_OfficeRubric = _resolveComponent("OfficeRubric");
  const _component_OfficeSpacer = _resolveComponent("OfficeSpacer");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$data.notFound ? (_openBlock(), _createBlock(_component_PageNotFound, {
    key: 0
  })) : _createCommentVNode("", true), !$data.notFound ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$data.loading ? (_openBlock(), _createBlock(_component_Loading, {
    key: 0
  })) : _createCommentVNode("", true), !$data.loading ? (_openBlock(), _createBlock(_component_CalendarCard, {
    key: 1,
    office: $props.office,
    "calendar-date": $props.calendarDate,
    card: $data.card,
    "service-type": $props.serviceType
  }, null, 8, ["office", "calendar-date", "card", "service-type"])) : _createCommentVNode("", true), $data.error ? (_openBlock(), _createBlock(_component_el_alert, {
    key: 2,
    title: $data.error,
    type: "error"
  }, null, 8, ["title"])) : _createCommentVNode("", true), _createVNode(_component_OfficeNav, {
    "calendar-date": $props.calendarDate,
    "selected-office": $props.office,
    "service-type": $props.serviceType
  }, null, 8, ["calendar-date", "selected-office", "service-type"]), $data.readyToSetFontSize ? (_openBlock(), _createBlock(_component_FontSizer, {
    key: 3
  })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.modules, module => {
    return _openBlock(), _createElementBlock("div", {
      key: module.name
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(module.lines, line => {
      return _openBlock(), _createElementBlock("div", {
        key: line.content
      }, [line.line_type == 'heading' ? (_openBlock(), _createBlock(_component_OfficeHeading, {
        key: 0,
        line: line
      }, null, 8, ["line"])) : _createCommentVNode("", true), line.line_type == 'subheading' ? (_openBlock(), _createBlock(_component_OfficeSubheading, {
        key: 1,
        line: line
      }, null, 8, ["line"])) : _createCommentVNode("", true), line.line_type == 'citation' ? (_openBlock(), _createBlock(_component_OfficeCitation, {
        key: 2,
        line: line
      }, null, 8, ["line"])) : _createCommentVNode("", true), line.line_type == 'html' ? (_openBlock(), _createBlock(_component_OfficeHTML, {
        key: 3,
        line: line
      }, null, 8, ["line"])) : _createCommentVNode("", true), line.line_type == 'leader' ? (_openBlock(), _createBlock(_component_OfficeLeader, {
        key: 4,
        line: line
      }, null, 8, ["line"])) : _createCommentVNode("", true), line.line_type == 'leader_dialogue' ? (_openBlock(), _createBlock(_component_OfficeLeaderDialogue, {
        key: 5,
        line: line
      }, null, 8, ["line"])) : _createCommentVNode("", true), line.line_type == 'congregation' ? (_openBlock(), _createBlock(_component_OfficeCongregation, {
        key: 6,
        line: line
      }, null, 8, ["line"])) : _createCommentVNode("", true), line.line_type == 'congregation_dialogue' ? (_openBlock(), _createBlock(_component_OfficeCongregationDialogue, {
        key: 7,
        line: line
      }, null, 8, ["line"])) : _createCommentVNode("", true), line.line_type == 'rubric' ? (_openBlock(), _createBlock(_component_OfficeRubric, {
        key: 8,
        line: line
      }, null, 8, ["line"])) : _createCommentVNode("", true), line.line_type == 'spacer' ? (_openBlock(), _createBlock(_component_OfficeSpacer, {
        key: 9
      })) : _createCommentVNode("", true)]);
    }), 128))]);
  }), 128))])]);
}