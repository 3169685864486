// @ is an alias to /src
import Loading from "@/components/Loading";
import CalendarCard from "@/components/CalendarCard";
import OfficeNav from "@/components/OfficeNav";
import Reading from "@/components/Reading";
import Collects from "@/components/Collects";
import CitationGroup from "@/components/CitationGroup";
import FontSizer from "@/components/FontSizer";
import PageNotFound from "@/views/PageNotFound";
import OfficeHeading from "@/components/OfficeHeading";
import OfficeSubheading from "@/components/OfficeSubheading";
import OfficeCitation from "@/components/OfficeCitation";
import OfficeHTML from "@/components/OfficeHTML";
import OfficeCongregation from "@/components/OfficeCongregation";
import OfficeLeader from "@/components/OfficeLeader";
import OfficeCongregationDialogue from "@/components/OfficeCongregationDialogue";
import OfficeLeaderDialogue from "@/components/OfficeLeaderDialogue";
import OfficeRubric from "@/components/OfficeRubric";
import OfficeSpacer from "@/components/OfficeSpacer";
export default {
  name: "Readings",
  components: {
    Loading,
    CalendarCard,
    OfficeNav,
    Reading,
    Collects,
    CitationGroup,
    PageNotFound,
    FontSizer,
    OfficeHeading,
    OfficeSubheading,
    OfficeCitation,
    OfficeHTML,
    OfficeCongregation,
    OfficeLeader,
    OfficeCongregationDialogue,
    OfficeLeaderDialogue,
    OfficeRubric,
    OfficeSpacer
  },
  props: {},
  data() {
    return {
      loading: true,
      error: false,
      calendarDate: null,
      card: null,
      selected: null,
      services: [],
      traditionalServices: [],
      contemporaryServices: [],
      service: null,
      notFound: false,
      modules: []
    };
  },
  computed: {
    serviceData: function () {
      return this.services.map(service => {
        return {
          active: service.name === this.service,
          name: service.name
        };
      });
    }
  },
  async mounted() {
    this.loading = true;
    this.service = "Great Litany";
    try {
      const data = await this.$http.get(`${process.env.VUE_APP_API_URL}api/v1/litany`);
      this.modules = data['data']['modules'];
      this.services = this.modules.map(module => {
        return {
          name: module.name,
          active: false
        };
      });
      this.contemporaryServices = this.services.filter(service => {
        return service.name.includes("Contemporary");
      });
      this.traditionalServices = this.services.filter(service => {
        return service.name.includes("Traditional");
      });
      this.contemporaryServices[0].active = true;
      this.selected = this.contemporaryServices[0];
      this.changeService(this.selected.name);
    } catch (e) {
      this.error = "There was an error retrieving the litany. Please try again.";
      this.loading = false;
      return;
    }
    this.error = false;
    this.loading = false;
  },
  methods: {
    serviceLink: function (service) {
      return `/litany`;
    },
    changeService: function (service) {
      this.service = service;
      this.selected = this.modules.find(module => module.name === service);
      this.contemporaryServices.forEach(service => {
        if (service.name == this.service) {
          service.active = true;
        } else {
          service.active = false;
        }
      });
      this.traditionalServices.forEach(service => {
        if (service.name == this.service) {
          service.active = true;
        } else {
          service.active = false;
        }
      });
      const link = this.serviceLink(service);
      history.pushState({}, null, link);
    }
  }
};