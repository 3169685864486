export default {
  props: ["readings"],
  methods: {
    readingID: function (reading) {
      const readingId = reading.citation.replace(/[\W_]+/g, "_");
      return `#reading_${readingId}`.toLowerCase();
    },
    goToReading: function (reading, length) {
      this.$emit('readingLinkClick', {
        reading: reading,
        length: length
      });
    }
  }
};