import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "full-width flex justify-center items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_alert = _resolveComponent("el-alert");
  return $data.displayBetaNote ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_alert, {
    class: "max-w-lg",
    title: "Beta Version",
    description: "You are viewing the beta version of the Daily Office site, which lets you preview features before they are launched. Please submit any suggestions or bug reports to feedback@dailyoffice2019.com.",
    type: "warning",
    effect: "dark",
    onClose: $options.dismissNote
  }, null, 8, ["onClose"])])) : _createCommentVNode("", true);
}