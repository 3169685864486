import Submenu from "@/components/Submenu";
export default {
  components: {
    Submenu
  },
  data() {
    return {
      activeIndex: "1",
      menuRouter: true,
      uniqueOpened: false
    };
  }
};