export default {
  props: ["reading", "id", "psalmCycle", "length", "translation", "psalmsTranslation", "psalmStyle"],
  data() {
    return {
      abbreviated: false,
      psalmCycle60: false
    };
  },
  computed: {
    showAbbreviatedToggle() {
      return this.reading.full.citation != this.reading.abbreviated.citation;
    },
    showPsalterCycleToggle() {
      return this.reading.full.cycle == 30 || this.reading.full.cycle == 60;
    },
    show() {
      return this.reading.full.cycle == null || this.reading.full.cycle == 30 && !this.psalmCycle60 || this.reading.full.cycle == 60 && this.psalmCycle60;
    },
    wrapperClass() {
      return this.reading.full.name.replace(" ", "_", "g").toLowerCase();
    }
  },
  watch: {
    psalmCycle: function () {
      this.psalmCycle60 = this.psalmCycle == "60";
    },
    length: function () {
      this.abbreviated = this.length == "abbreviated";
    }
  },
  created() {
    this.psalmCycle60 = this.psalmCycle == "60";
    this.abbreviated = this.length == "abbreviated";
  },
  methods: {
    readingID: function (reading) {
      const readingId = reading.citation.replace(/[\W_]+/g, "_");
      return `reading_${readingId}`.toLowerCase();
    },
    setCycle(status) {
      if (status) {
        this.$emit('cycle-60');
      } else {
        this.$emit('cycle-30');
      }
    },
    scriptureLink(abbreviated = false) {
      const reading = abbreviated ? this.reading.abbreviated : this.reading.full;
      if (reading.name.includes("The Psalm")) {
        const psalmsTranslation = this.psalmsTranslation;
        if (psalmsTranslation == "traditional") {
          return "(Traditional)";
        }
        return "(Contemporary)";
      }
      let url = "";
      let abbreviation = this.translation.toLowerCase();
      if (['esv', 'niv', 'nasb'].includes(abbreviation) && this.reading.full.testament == "DC") {
        abbreviation = 'nrsvce';
      }
      if (abbreviation == 'kjv' && this.reading.full.testament == "DC") {
        url = `https://bible.oremus.org/?version=AV&passage=${reading.citation}`;
      } else if (abbreviation == "esv") {
        url = `https://www.esv.org/${reading.citation}`;
      } else {
        url = `https://www.biblegateway.com/passage/?search=${reading.citation}&version=${abbreviation}`;
      }
      abbreviation = abbreviation == "nrsvce" ? "nrsv" : abbreviation;
      return `<a target="_blank" href="${url}">(${abbreviation.toUpperCase()})</a>`;
    }
  }
};