import { DynamicStorage } from "@/helpers/storage";
export default {
  data() {
    return {
      userTheme: "light"
    };
  },
  async mounted() {
    let activeTheme = await DynamicStorage.getItem("user-theme");
    if (!activeTheme) {
      activeTheme = this.getMediaPreference();
    }
    this.setTheme(activeTheme, false);
    this.$watch("userTheme", this.setTheme);
  },
  methods: {
    async setTheme(theme, store = true) {
      if (store) {
        await DynamicStorage.setItem("user-theme", theme);
      }
      this.userTheme = theme;
      document.documentElement.className = this.getUserThemeClass();
    },
    getUserThemeClass() {
      return this.userTheme;
    },
    getMediaPreference() {
      const hasDarkPreference = window.matchMedia("(prefers-color-scheme: dark)").matches;
      if (hasDarkPreference) {
        return "dark";
      } else {
        return "light";
      }
    }
  }
};