import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "font-size-block my-2 p-3"
};
const _hoisted_2 = {
  class: "w-1/6 inline-block"
};
const _hoisted_3 = {
  class: "w-2/3 inline-block"
};
const _hoisted_4 = {
  class: "w-1/6 inline-block text-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_el_slider = _resolveComponent("el-slider");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_font_awesome_icon, {
    icon: ['fad', 'font-case'],
    size: "sm"
  })]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_slider, {
    modelValue: $data.fontSize,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.fontSize = $event),
    class: "w-3/4",
    min: $data.sliderMin,
    max: $data.sliderMax,
    "format-tooltip": $options.displayFontSize,
    onInput: $options.setFontSize
  }, null, 8, ["modelValue", "min", "max", "format-tooltip", "onInput"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_font_awesome_icon, {
    icon: ['fad', 'font-case'],
    size: "lg"
  })])]);
}