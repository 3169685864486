import EmailSignup from "@/components/EmailSignup";
import ShareSettings from "@/components/ShareSettings";
import ThemeSwitcher from "@/components/ThemeSwitcher";
import SubmitFeedback from "@/components/SubmitFeedback";
export default {
  components: {
    EmailSignup,
    ShareSettings,
    ThemeSwitcher,
    SubmitFeedback
  }
};