import TopMenu from "@/components/TopMenu";
import Loading from "@/components/Loading";
import AHPLogo from "@/components/AHPLogo";
import { event } from 'vue-gtag';
import BetaNote from "@/components/BetaNote";
import { useActiveMeta, useMeta } from "vue-meta";
import ShareSettings from "@/components/ShareSettings.vue";
import EmailSignup from "@/components/EmailSignup.vue";
import SubmitFeedback from "@/components/SubmitFeedback.vue";
import AdditionalLinks from "@/components/AdditionalLinks.vue";
import { ArrowDown } from '@element-plus/icons-vue';
export default {
  components: {
    AHPLogo,
    TopMenu,
    Loading,
    BetaNote,
    ShareSettings,
    EmailSignup,
    SubmitFeedback,
    AdditionalLinks,
    ArrowDown
  },
  setup() {
    const {
      meta
    } = useMeta({
      htmlAttrs: {
        lang: 'en'
      },
      viewport: 'width=device-width, initial-scale=1.0, viewport-fit=cover'
    });
    const metadata = useActiveMeta();
  },
  data() {
    return {
      loading: true,
      open: false,
      isActive: true,
      name: this.$route.name,
      error: false,
      showLinks: false
    };
  },
  computed: {
    isPray() {
      return this.$route.name === 'Pray' || this.$route.name === 'Home' ? 'primary' : '';
    },
    isSettings() {
      return this.$route.name === 'Settings' ? 'primary' : '';
    },
    isCalendar() {
      return this.$route.name === 'calendar' ? 'primary' : '';
    },
    isOther() {
      return this.$route.name === 'About' || this.$route.name === 'Collects' || this.$route.name === 'readings' || this.$route.name === 'litany' || this.$route.name === 'Psalms' ? 'primary' : '';
    }
  },
  async created() {
    document.title = "The Daily Office";
    try {
      event('betaPageView');
      const settings_data = await this.$http.get(`${process.env.VUE_APP_API_URL}api/v1/available_settings/`);
      await this.$store.commit("saveAvailableSettings", settings_data.data);
      await this.$store.dispatch('initializeSettings');
      this.loading = false;
      await this.$nextTick();
      this.showLinks = true;
    } catch (e) {
      console.log(e);
      this.error = "There was an error loading the settings. Please try refreshing the page.";
      this.loading = false;
      await this.$nextTick();
      this.showLinks = true;
      return;
    }
    this.loading = false;
    await this.$nextTick();
    this.showLinks = true;
  }
};