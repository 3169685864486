import { DynamicStorage } from "@/helpers/storage";
export default {
  name: "FontSizer",
  components: {},
  props: {},
  data() {
    return {
      fontSize: 24,
      sliderMin: 10,
      sliderMax: 40
    };
  },
  async mounted() {
    await this.resetFontSize();
  },
  methods: {
    async resetFontSize() {
      await this.$nextTick();
      this.fontSize = parseInt((await DynamicStorage.getItem("fontSize")) || 24);
      await this.setFontSize(this.fontSize);
    },
    async setFontSize(value) {
      const main = document.getElementById("main");
      if (main) {
        main.style["font-size"] = `${value}px`;
        document.querySelectorAll("#main h2, #main h3, #main p, .el-collapse-item__header, .el-collapse-item p").forEach(p => {
          p.style["font-size"] = `${value}px`;
          p.style["line-height"] = `${value * 1.6}px`;
        });
      }
      await DynamicStorage.setItem("fontSize", this.fontSize);
      const test = await DynamicStorage.getItem("fontSize");
    },
    displayFontSize(value) {
      return `${value}px`;
    }
  }
};