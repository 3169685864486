import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "small-container"
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  key: 1,
  class: "alert-danger"
};
const _hoisted_4 = {
  key: 2,
  class: "day"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PageNotFound = _resolveComponent("PageNotFound");
  const _component_Loading = _resolveComponent("Loading");
  const _component_CalendarCard = _resolveComponent("CalendarCard");
  const _component_OfficeNav = _resolveComponent("OfficeNav");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.notFound ? (_openBlock(), _createBlock(_component_PageNotFound, {
    key: 0
  })) : _createCommentVNode("", true), !$data.notFound ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$data.loading ? (_openBlock(), _createBlock(_component_Loading, {
    key: 0
  })) : _createCommentVNode("", true), $data.error ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($data.error), 1)) : _createCommentVNode("", true), !$data.loading ? (_openBlock(), _createElementBlock("div", _hoisted_4, [!$data.loading ? (_openBlock(), _createBlock(_component_CalendarCard, {
    key: 0,
    "calendar-date": $data.calendarDate,
    card: $data.card
  }, null, 8, ["calendar-date", "card"])) : _createCommentVNode("", true), _createVNode(_component_OfficeNav, {
    "calendar-date": $data.calendarDate,
    "service-type": $data.currentServiceType,
    "selected-office": 'day'
  }, null, 8, ["calendar-date", "service-type"])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]);
}