// @ is an alias to /src

import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { ElMessage } from "element-plus";
import { getMessageOffset } from "@/helpers/getMessageOffest";
export default {
  name: "SettingsPanel",
  components: {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption
  },
  props: ["availableSettings", "site", "name", "advanced"],
  methods: {
    async changeSetting() {
      const settings = await this.$store.state.settings;
      this.availableSettings.forEach(setting => {
        if (setting.active) {
          const name = setting.name;
          const value = setting.active;
          settings[name] = value;
        }
      });
      await this.$store.commit("saveSettings", settings);
      return ElMessage.success({
        title: "Saved",
        message: "Your setting has been saved.",
        showClose: true,
        offset: getMessageOffset()
      });
    },
    showSetting(setting) {
      const correct_site = setting.site_name == this.site;
      const show_advanced = setting.setting_type == 1 || this.advanced;
      return correct_site && show_advanced;
    }
  }
};