import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "small-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ClassicSite = _resolveComponent("ClassicSite");
  const _component_el_col = _resolveComponent("el-col");
  const _component_ShareSettings = _resolveComponent("ShareSettings");
  const _component_EmailSignup = _resolveComponent("EmailSignup");
  const _component_SubmitFeedback = _resolveComponent("SubmitFeedback");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    justify: "center",
    class: "text-center mx-auto"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 6
    }, {
      default: _withCtx(() => [_createVNode(_component_ClassicSite)]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 6
    }, {
      default: _withCtx(() => [_createVNode(_component_ShareSettings, {
        ref: "shareSettings"
      }, null, 512)]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 6
    }, {
      default: _withCtx(() => [_createVNode(_component_EmailSignup, {
        ref: "emailSignup"
      }, null, 512)]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 6
    }, {
      default: _withCtx(() => [_createVNode(_component_SubmitFeedback, {
        ref: "submitFeedback"
      }, null, 512)]),
      _: 1
    })]),
    _: 1
  })]);
}