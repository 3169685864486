import ShareSettings from "@/components/ShareSettings.vue";
import EmailSignup from "@/components/EmailSignup.vue";
import SubmitFeedback from "@/components/SubmitFeedback.vue";
import ClassicSite from "@/components/ClassicSite.vue";
export default {
  components: {
    ShareSettings,
    EmailSignup,
    SubmitFeedback,
    ClassicSite
  },
  data() {
    return {};
  }
};