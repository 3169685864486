export default {
  data() {
    return {
      emailField: null,
      feedbackPanel: false,
      windowWidth: window.innerWidth,
      success: false,
      error: null,
      loading: false,
      panelSize: "37%"
    };
  },
  created: function () {
    window.addEventListener("resize", this.setPanelSize);
    this.setPanelSize();
  },
  unmounted() {
    window.removeEventListener("resize", this.setPanelSize);
  },
  methods: {
    setPanelSize() {
      if (window.innerWidth < 1024) {
        this.panelSize = "90%";
      } else {
        this.panelSize = "37%";
      }
    },
    showFeedbackPanel() {
      this.feedbackPanel = true;
    }
  }
};